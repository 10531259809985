import React from "react";

// Lazy load profile components
export const Profile = React.lazy(() => import("./Profile"));

// Lazy load warehouse components
export const Warehouses = React.lazy(() => import("./Warehouses"));

// Lazy load currency components
export const Currencies = React.lazy(() => import("./Currencies"));

// Lazy load branch components
export const Branches = React.lazy(() => import("./Branches"));

// Lazy load opening balance components
export const OpeningBalances = React.lazy(() => import("./OpeningBalances"));
export const OpeningBalancesEdit = React.lazy(() =>
  import("./OpeningBalancesEdit")
);

// Lazy load transaction number series components
export const TransactionNumberSeries = React.lazy(() =>
  import("./TransactionNumberSeries")
);

// Lazy load tax components
export const Taxes = React.lazy(() => import("./Taxes"));

// Lazy load user components
export const Users = React.lazy(() => import("./Users"));

// Lazy load role components
export const Roles = React.lazy(() => import("./Roles"));
export const RolesNew = React.lazy(() => import("./RolesNew"));
export const RolesEdit = React.lazy(() => import("./RolesEdit"));

// Lazy load delivery method components
export const DeliveryMethods = React.lazy(() => import("./DeliveryMethods"));

// Lazy load payment mode components
export const PaymentModes = React.lazy(() => import("./PaymentModes"));

// Lazy load reason components
export const Reasons = React.lazy(() => import("./Reasons"));

// Lazy load shipment preference components
export const ShipmentPreferences = React.lazy(() =>
  import("./ShipmentPreferences")
);

// Lazy load sales person components
export const SalesPersons = React.lazy(() => import("./SalesPersons"));

// Lazy load opening balance account details components
export const OpeningBalanceAccDetails = React.lazy(() =>
  import("./OpeningBalanceAccDetails")
);

// Lazy load opening balance account details components
export const TaxRates = React.lazy(() => import("./TaxRates"));

// Lazy load product conversion link
export const ProductConversionLink = React.lazy(() =>
  import("./ProductConversionLink")
);
