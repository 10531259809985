import React from "react";
import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
  split,
  Observable,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { getMainDefinition } from "@apollo/client/utilities";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Wrapper from "./localeWrapper";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import LocalStorageService from "./services/local_storage";
// const uri = "http://139.59.236.210:3000/query";
// const uri = "http://192.168.88.62:8081/query";
// const uri = "http://192.168.88.161:8081/query";
const uri = "https://graphql.sentosalink.com/query";
// const uri = "http://192.168.88.111:8081/query";
// const uri = "http://localhost:8081/query";

// Timeout duration (in milliseconds)
const TIMEOUT = 10000; // Set your timeout here, e.g., 10000ms (10 seconds)

// Create a custom timeout link
const timeoutLink = new ApolloLink((operation, forward) => {
  // Check if the operation is a query
  const definition = getMainDefinition(operation.query);
  const isQuery =
    definition.kind === "OperationDefinition" &&
    definition.operation === "query";

  // Only apply timeout to queries
  if (!isQuery) {
    return forward(operation); // Skip timeout for mutations
  }

  return new Observable((observer) => {
    const handle = setTimeout(() => {
      observer.error(new Error(`Network timed out`));
    }, TIMEOUT);

    const subscription = forward(operation).subscribe({
      next: (result) => {
        clearTimeout(handle);
        observer.next(result);
      },
      error: (error) => {
        clearTimeout(handle);
        observer.error(error);
      },
      complete: () => {
        clearTimeout(handle);
        observer.complete();
      },
    });

    return () => {
      clearTimeout(handle);
      subscription.unsubscribe();
    };
  });
});

const httpLink = new HttpLink({
  uri,
  fetchOptions: {
    retry: false, // Disable retry on HttpLink level, as we handle it globally
  },
  // Enable support for React Suspense
  watchQuery: {
    fetchPolicy: "cache-and-network",
    suspense: true,
  },
});

const uploadLink = createUploadLink({
  uri,
});

const retryLink = new RetryLink({
  delay: {
    initial: 300, // Initial delay in milliseconds before retrying
    max: 2000, // Maximum delay between retries
    jitter: true, // Randomize retry delay
  },
  attempts: {
    max: 3, // Maximum number of retry attempts
    retryIf: (error, operation) => {
      // Retry only if it's a query and there is a network error
      return operation.operationName !== "mutation" && !!error;
    },
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = LocalStorageService.getToken();
  // console.log('token.....',token)
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      token: token ? token : "",
    },
  }));

  return forward(operation);
});

const isUnauthorized = (errors = []) => {
  if (Array.isArray(errors)) {
    if (errors.length === 1) {
      const errCode = errors[0].message;
      if (errCode && errCode === "Access Denied") {
        return true;
      }
    }
  }
  return false;
};
// Error link to catch 401 Unauthorized responses and handle network errors
const errorLink = onError(({ networkError, graphQLErrors }) => {
  // if (networkError?.statusCode === 401) {
  //   // Handle 401 error (Unauthorized)
  //   // Optionally clear the token and redirect to login page
  //   localStorage.clear();
  //   window.location.href = "/login"; // Adjust the URL according to your app's route
  // } else if (networkError) {
  //   console.error(`[Network error]: ${networkError}`);
  // }
  if (isUnauthorized(graphQLErrors)) {
    localStorage.clear();
    window.location.href = "/";
  }
});

// check if the operation involves file upload
const isUploadOperation = ({ variables }) => {
  return Object.values(variables).some(
    (value) =>
      value instanceof File ||
      (Array.isArray(value) && value.some((file) => file instanceof File))
  );
};

const link = split(
  // split based on operation type
  ({ query, variables }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "mutation" &&
      isUploadOperation({ variables })
    );
  },
  uploadLink,
  httpLink
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          paginateJournal: relayStylePagination(),
          paginateJournalReport: relayStylePagination(),
          paginateDetailedGeneralLedgerReport: relayStylePagination(),
          paginateAccountTransactionReport: relayStylePagination(),
          paginateProductCategory: relayStylePagination(),
          paginateProductUnit: relayStylePagination(),
          paginateProduct: relayStylePagination(),
          paginateProductGroup: relayStylePagination(),
          paginatePurchaseOrder: relayStylePagination(),
          paginateSupplier: relayStylePagination(),
          paginateBill: relayStylePagination(),
          paginateSupplierCredit: relayStylePagination(),
          paginateCustomer: relayStylePagination(),
          paginateSalesPerson: relayStylePagination(),
          paginateSalesOrder: relayStylePagination(),
          paginateCustomerPayment: relayStylePagination(),
          paginateExpense: relayStylePagination(),
          paginateInvoice: relayStylePagination(),
          paginateCreditNote: relayStylePagination(),
          paginateSupplierPayment: relayStylePagination(),
          paginateTransferOrder: relayStylePagination(),
          paginateInventoryAdjustment: relayStylePagination(),
          paginateSalesInvoice: relayStylePagination(),
          paginateBankingTransaction: relayStylePagination(),
          paginateRecurringExpense: relayStylePagination(),
          paginateProductConversionLink: relayStylePagination(),
          paginateProductConversionTransaction: relayStylePagination(),
        },
      },
    },
  }),
  link: ApolloLink.from([
    retryLink,
    timeoutLink,
    authMiddleware,
    errorLink,
    link,
  ]),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Wrapper>
        <App />
      </Wrapper>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
